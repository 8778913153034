<template>
  <div>
    <a-form class="custom-nested-search-form" :form="form" @submit="handleSearch">
      <a-row :gutter="24">
        <a-col :span="8" :xxl="6">
          <a-form-item label="日期">
            <a-range-picker v-decorator="['created_at']" />
          </a-form-item>
        </a-col>

        <a-col v-if="this.$store.getters.userRole === 'tenant_admin'" :span="8" :xxl="6">
          <a-form-item label="状态">
            <a-select
              v-decorator="['status']"
              placeholder="请选择状态"
              allow-clear
            >
              <a-select-option value="uncheck">
                待审核
              </a-select-option>
              <a-select-option value="checking">
                审核中
              </a-select-option>
              <a-select-option value="passed">
                审核通过
              </a-select-option>
              <a-select-option value="no_passed">
                审核未通过
              </a-select-option>
              <a-select-option value="cancelled">
                已取消
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col v-else :span="8" :xxl="6">
          <a-form-item label="状态">
            <a-select
              v-decorator="['status']"
              placeholder="请选择状态"
              allow-clear
            >
              <a-select-option value="uncheck">
                待审核
              </a-select-option>
              <a-select-option value="passed">
                审核通过
              </a-select-option>
              <a-select-option value="no_passed">
                审核未通过
              </a-select-option>
              <a-select-option value="cancelled">
                已取消
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6">
          <a-form-item label="信息维护类型">
            <a-select
              @focus="fetchInfoModifyApplyInfoTypeOptions"
              placeholder="请选择信息维护类型"
              v-decorator="['info_type', {
                rules: [{ type: 'string',message: '请选择信息维护类型' }]
              }]"
            >
              <a-select-option
                v-for="infoType in infoModifyApplyInfoTypes"
                :key="infoType.slug"
                :value="infoType.slug"
              >
                {{ infoType.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8" :xxl="6">
          <a-form-item label="申请人">
            <a-select
              show-search
              :filter-option="filterOption"
              option-filter-prop="children"
              placeholder="请选择申请人"
              @focus="fetchTenantUserOptions"
              v-decorator="['apply_user_id', {
                rules: [{ type: 'number',message: '请选择申请人' }]
              }]"
            >
              <a-select-option
                v-for="applyUser in applyUserOptions"
                :key="applyUser.id"
                :value="applyUser.id"
              >
                {{ applyUser.emp_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8" :xxl="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { formatRangeBeginDate, formatRangeEndDate } from '@/utils/time'
import { findTenantUserWithAdminOptions } from '@/api/user'
import { findInfoModifyApplyInfoTypeOptions } from '@/api/info_modify_apply'

export default {
  name: 'SearchInfoModifyApply',
  data() {
    return {
      form: this.$form.createForm(this, { name: 'info_modify_apply_search' }),
      applyUserOptions: [],
      infoModifyApplyInfoTypes: []
    }
  },
  methods: {
    // 加载下拉框选项
    fetchTenantUserOptions() {
      if (this.applyUserOptions.length === 0) {
        findTenantUserWithAdminOptions().then((res) => {
          this.applyUserOptions = res.data
        })
      }
    },

    fetchInfoModifyApplyInfoTypeOptions() {
      if (this.infoModifyApplyInfoTypes.length === 0) {
        findInfoModifyApplyInfoTypeOptions().then((res) => {
          this.infoModifyApplyInfoTypes = res.data
        })
      }
    },

    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },

    handleSearch(e) {
      e.preventDefault()
      const fieldsValue = this.form.getFieldsValue()
      const values = {
        ...fieldsValue,
        created_at_begin: formatRangeBeginDate(fieldsValue.created_at),
        created_at_end: formatRangeEndDate(fieldsValue.created_at)
      }
      this.$emit('submit', values)
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>

