import request from '@/utils/request'

// 更新申请
export function checkInfoModifyApply(id, data) {
  return request({
    url: `/info_modify_applies/${id}/check`,
    method: 'patch',
    data
  })
}

// 查询申请记录列表
export function findInfoModifyApplies(params) {
  return request({
    url: `/info_modify_applies`,
    method: 'get',
    params: params
  })
}

// 查询记录详情
export function findInfoModifyApplyDetail(id) {
  return request({
    url: `/info_modify_applies/${id}/detail`,
    method: 'get'
  })
}

// 获取信息维护类型
export function findInfoModifyApplyInfoTypeOptions() {
  return request({
    url: `/info_modify_applies/options`,
    method: 'get'
  })
}
