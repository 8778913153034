<template>
  <div>
    <search-info-modify-apply @submit="submitSearch" />

    <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="sortChange"
    >
      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="showDetailModal(record)">详情</a>
          <a @click="showOperationHistoryModal(record.id)">操作历史</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 详情模态框 -->
    <detail-info-modify-apply
      v-if="isShowDetailModal"
      :visible.sync="isShowDetailModal"
      :id="detailingRecordId"
      @completed="fetchData"
    />

    <operation-history-list-modal
      v-if="isShowOperationHistoryModal"
      :reference-id="showingOperationHistoryId"
      reference-type="InfoModifyApply"
      :visible.sync="isShowOperationHistoryModal"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import SearchInfoModifyApply from '@/views/info_modify_applies/Search'
import { findInfoModifyApplies } from '@/api/info_modify_apply'

export default {
  name: 'InfoModifyApplyList',
  components: {
    Pagination,
    SearchInfoModifyApply,
    DetailInfoModifyApply: () => import('@/views/info_modify_applies/Detail'),
    OperationHistoryListModal: () => import('@/views/operation_histories/Modal')
  },
  data() {
    return {
      query: {},
      data: [],
      loading: true,
      isShowDetailModal: false, // 是否显示详情表单弹窗
      detailingRecordId: {}, // 正在编辑的记录id
      showingOperationHistoryId: 0,
      isShowOperationHistoryModal: false,
      pagination: {
        total_count: 0
      },
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '申请时间',
          dataIndex: 'apply_time',
          width: 200,
          fixed: 'left',
          sorter: true,
          sortOrder: this.sort.sort_field === 'apply_time' ? this.sort.sort_order : false
        },
        {
          title: '申请人',
          dataIndex: 'apply_user_name'
        },
        {
          title: '信息维护类型',
          dataIndex: 'info_type'
        },
        {
          title: '状态',
          dataIndex: 'status'
        },
        {
          title: '操作',
          width: 220,
          fixed: 'right',
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    }
  },
  methods: {
    showDetailModal(record) {
      this.detailingRecordId = record.id
      this.isShowDetailModal = true
    },

    // 显示操作历史
    showOperationHistoryModal(id) {
      this.showingOperationHistoryId = id
      this.isShowOperationHistoryModal = true
    },

    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findInfoModifyApplies(Object.assign({}, this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    }
  }
}
</script>
